<template>
  <div class="home">
      <div class="main-hero">        
          <div class="main-hero__text">
            <b-container fluid="xl">
              <b-row>
                <b-col cols="12" md="6" lg="5">
                    <div class="hero-block">
                      <h1 class="h1-dots">Bemutatkozás</h1>
                      <p>A Kunsági Éden egy apró vidéki üzemből nőtte ki magát nemzetközi vállalkozássá. A csodás magyar termőföld, az itt dolgozó nagyszerű emberek elhivatottsága és szakértelme, a korszerű technológia folyamatos fejlesztése mind biztosíték a Kunsági Éden termékcsalád  megszokottan magas minőségére.</p>
                      <div class="btn-wrap">
                          <router-link :to="{ path: '/rolunk'}" class="btn btn--primary" @click.native="setDataLayer('introduction', 'learn_more', '')">Többet szeretnék tudni</router-link>
                      </div>   
                    </div>               
                </b-col>
              </b-row>           
            </b-container>
          </div>
          <div class="quarter-design-circle"></div>
      </div>            
      <ProductsRow />
      <b-container fluid="xl" v-if="recipeList.length > 0">
        <div class="title-wrap other-recipes-block">
            <h2>Receptek</h2>
            <router-link class="btn btn--brown" :to="{name: 'Recipes'}" @click.native="setDataLayer('all_content_click', 'all_recipe_click', '')">További receptek</router-link>
        </div>
        <b-row>
            <b-col cols="12" md="6" lg="4" v-for="recipe in recipeList" :key="recipe.id" class="recipe-item-wrap">
                <RecipeItem :recipe="recipe" />
            </b-col>          
        </b-row>        
        <div class="btn-mobile-wrap">
          <router-link class="btn btn--brown btn--mobile" :to="{name: 'Recipes'}" @click.native="setDataLayer('all_content_click', 'all_recipe_click', '')">További receptek</router-link>        
        </div>
      </b-container>
      <div class="social-wrap">
        <div class="half-left-dc"></div>        
        <Socialnetwork  />  
      </div>
  </div>
</template>

<script>
import apiH from '@/mixins/apiHandlerMI.js'
import ProductsRow from '@/components/layout/ProductsRow'
import Socialnetwork from '@/components/layout/Socialnetwork'
import RecipeItem from '@/components/pages/RecipeItem.vue'

export default {
  name: 'Home',
  mixins: [apiH],   
  components: {
    ProductsRow,
    Socialnetwork,
    RecipeItem
  },
  created(){
    this.loadRecipes()
  },
  mounted(){
        this.setMetaTags({
            title: 'Kunsági Éden', 
            desc: '', 
            keywords: '', 
            ogType: 'website', 
            ogTitle: 'Kunsági Éden',                         
            ogImage: null, 
            ogImageWidth: null, 
            ogImageHeight: null, 
            ogDesc:''
        })
  },
  data(){
    return{
      recipeList:[]
    }
  },
  methods:{
    loadRecipes(){
        var _this = this;                       
        _this.get('recipe',
            {
                params: {      
                    limit:6,
                    relations:1,                                       
                    where: [['where','status', '1']],
                    order_by:[
                        ['created_at','desc'],
                        ['sort_order','asc']                
                    ],  
                }
            },
            function(resp){                                        
                _this.recipeList = resp.data.recipe

                //fill search helper
                for (var i in _this.recipeList){
                    _this.recipeList[i].search_helper = _this.recipeList[i].name.toLowerCase();
                    _this.recipeList[i].search_helper += " " + _this.recipeList[i].description.toLowerCase();
                    _this.recipeList[i].search_helper += " " + _this.recipeList[i].short_description.toLowerCase();
                }
                
            }, function(err){
                console.log(err);
                _this.recipeList = []
            }
        )
    },    
  }
}
</script>
